import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';
import useSessionStore from '@services/stores/SessionStore';
import { useEffect, useState } from 'react';
import { useTranslation } from '@tools/i18n';
import { TextField } from '@material-ui/core';
import { ucfirst } from '@services/Utils';
import theme from '@components/theme';

const useStyles = makeStyles({
    input: {
        '& .MuiOutlinedInput-notchedOutline': {
            padding: ' 6px 10px',
            borderRadius: '0 !important',
            border: '2px solid #041e3a63',
            outline: 0,
        },
        '& .MuiOutlinedInput-root': {
            padding: 6,
        },
        '&.has-errors .MuiOutlinedInput-notchedOutline2': {
            borderColor: theme.palette.primary.darker,
        },
    },
    label: {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    errors: {
        display: 'grid',
        padding: '6px 8px',
        color: '#ffffff',
        gap: 4,
        fontSize: 12,
        background: theme.palette.primary.darker,
    },
}, { name: 'np-country-selector' });

export default function NewportCountrySelector(
    {
        onChange,
        label = null,
        errors = undefined,
    },
) {
    const classes = useStyles();
    const sessionMethods = useSessionStore((state) => state.methods);
    const countries = useSessionStore((state) => state.session.countries);
    const { t } = useTranslation();
    const autoCountryCode = useSessionStore((state) => state.session.geoIpCountry);
    const [ id, setId ] = useState(
        `np-input-${Math.ceil(new Date() * Math.random()) + Math.floor(Math.random() * 100000)}`,
    );
    const [ value, setValue ] = useState(null);

    const options = countries.map((country) => {
        return {
            code: country.iso || '',
            label: country.translated.name || country.name || '',
        };
    }).sort((a, b) => a.label.localeCompare(b.label,  ['sv', 'en'], {sensitivity: 'case'}));

    useEffect(() => {
        sessionMethods.getCountries().then();
        if (options.length === 1) {
            setValue(options[0] || null);
        } else {
            setValue(options.find(option => option.code === autoCountryCode) || null);
        }
    }, [ options ]);

    function renderLabel(label) {
        if (!label) {
            return null;
        }

        return <>
            <label className={classes.label} htmlFor={id}>
                {label}
            </label>
        </>;
    }

    function validation(errors) {
        if (!Array.isArray(errors)) {
            return null;
        }

        return <div className={classes.errors}>
            {errors.map((error) => {
                return <span>{ucfirst(error)}</span>;
            })}
        </div>;
    }

    function errorClass() {
        return errors?.length > 0 ? 'has-errors' : null;
    }

    return <div className={classes.inputWrapper}>
        {renderLabel(label)}
        <Autocomplete
            className={[ classes.input, errorClass() ].join(' ')}
            renderInput={(params) => <TextField
                {...params}
                variant={'outlined'}
                id={id}
            />}
            renderOption={(props, option) => {
                return `${props.label} (${props.code})`;
            }}
            getOptionLabel={(option) => option.label}
            options={options}
            value={value}
            onChange={(event, value, reason) => {
                setValue(value);
                if (onChange) {
                    onChange(event, value);
                }
            }}
        />
        {validation(errors)}
    </div>;
}
